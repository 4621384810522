const compTypes = {
  IMAGE_COMPONENT: 'StaticImage', // 静态图组件（单图、双图、三图、四图）
  TEXT_EDIT: 'TextEdit', // 图文组件
  MULTI_TEXT: 'MultiText', // 多图文字-红人业务
  TEXT_LIST: 'NewsRoomArticle', // 新闻文章列表组件
  CODE_IMAGE_COMPONENT: 'StaticImage', // code合图组件
  HORIZONTAL_COUPON: 'HorizontalCoupon', // 通用横向券
  VERTICAL_COUPON: 'VerticalCoupon', // 通用纵向券
  STORE_HORIZONTAL_COUPON: 'StoreHorizontalCoupon', // 店铺横向券
  STORE_VERTICAL_COUPON: 'StoreVerticalCoupon', // 店铺纵向券
  PLATFORM_HORIZONTAL_COUPON: 'StoreHorizontalCoupon', // 平台横向优惠券（店铺）
  PLATFORM_VERTICAL_COUPON: 'StoreVerticalCoupon',  // 平台纵向优惠券 （店铺）
  HORIZONTAL_ITEMS: 'HorizontalItems', // 横向商品列表
  VERTICAL_ITEMS: 'VerticalItems', // 纵向商品列表
  DAILYNEW_ITEMS: 'DailyNewItems',  // dailynew商品组件
  LEFT_PICTURE_ITEMS: 'LeftPictrueItems',  // 左图右商品组件
  IMAGE_CAROUSEL_COMPONENT: 'SideslipImage', // 滑动多图组件 （轮播图、横滑3、4、5、6）
  STORE_INFO_COMPONENT: 'ShopBanner', // 店招
  SHOPACTIVITY_COMPONENT: 'ShopActivity', // 店铺促销活动组件
  STORE_HOT_SEARCHTERM_RECOMMEND: 'StoreHotSearchtermRecommend', // 店铺热搜词
  CATEGORY_ITEMS_DYNAMIC: 'CategoryItemsDynamic', // 商品列表组件
  FLASH_SALE: 'FlashSale', // 闪购
  ONE_THIRD_CONTAINER_COMPONENT: 'OneThirdContainerComponent', // 1/3组件
  HORIZONTAL_ANCHOR: 'HorizontalAnchor', // 锚点组件（文案锚点）
  HORIZONTAL_ANCHOR_IMAGE: 'HorizontalAnchorImage', // 锚点组件（图片锚点）
  COUNTDOWN_COMPONENT: 'Countdown', // 倒计时
  RANKING_ENTRANCE: 'RankingEntrance', // 排行榜入口
  RANKING_LIST: 'RankingList', // 排行榜
  CALENDAR: 'Calendar', // 日历
  VIDEO_COMPONENT: 'Video',  // 视频组件
  POINTS_COMPONENT: 'Integral', // 领积分
  SUBSCRIBE_COMPONENT: 'Subscribe', // 订阅组件
  SURVEY_COMPONENT: 'Survey', // 意见收集组件
  PK_COMPONENT: 'PK', // PK投票组件
  PRODUCT_RECOMMEND_FLOW: 'ProductRecommend', // 商品推荐组件
  PRODUCT_RECOMMEND_FOR_SHOP: 'ProductRecommend', //  店铺专用推荐组件
  PRODUCT_RECOMMEND_HORIZONTAL: 'ProductRecommend', // 横滑推荐商品
  RANKING_ENTRANCE_RECOMMEND: 'RankRecommend', // 排行榜组件
  BRAND_RECOMMEND_COMPONENT: 'BrandRecommend', // 品牌推荐
  LOOKBOOK: 'LookBook',
  SHOPCARD_COMPONENT: 'StoreSingleCard', // 店铺单卡片组件
  SHOPLIST_COMPONENT: 'StoreCardList', // 店铺卡片列表组件
  MULTI_SHOP_COMPONENT: 'StoreMultiSlide', //  多店铺横滑组件
  MULTI_SHOP_ONEITEM: 'StoreMultiSlideOne', //多店铺一店一品组件
  MULTI_SHOP_ITEMCARD: 'StoreMultiSlideCard', // 多店铺圆角卡片组件
  CATEGORY_RECOMMEND_DYNAMIC: 'CategoryRecommendDynamic', // 自动轮播组件
  CATEGORY_BRAND_ITEM_DYNAMIC: 'CategoryBrandItem', // 品类品牌单品
  SINGLE_PRODUCT_DYNAMIC: 'SingleProduct', // 单品
  ACTIVITY_BANNER: 'ActivityBanner', // 活动banner组件
  SINGLE_ACTIVITY_BANNER: 'StorePageSingleActivityBanner', // 单活动banner
  MULTI_ACTIVITIES_BANNER: 'StorePageSingleActivityBanner', // 多活动banner
  BRAND_DISPLAY_BANNER_DYNAMIC: 'BrandDisplay', // 品牌展示组件
  CATEGORY_DISPLAY_COMPONENT: 'CategoryDisplay', // 品类展示组件
  COLLOCATION_DISPLAY_COMPONENT: 'CollocationDisplay', // 搭配展示组件
  SHEIN_X: 'SheinXList', // 设计师组件
  CLUB_COMPONENT: 'ClubPromotion', // 付费会员组件
  SHEIN_X_VOTE: 'SheinXVote', // 设计师投票组件
  NEWUSER_PACKAGE: 'NewUserPackage', // 推客券包组件
  LOWEST_PRICE: 'LowestPrice', // 历史最低价组件
  HALF_COMPONENT_CONTAINER: 'HalfComponentContainer', // 1/2组件（闪购、商品）
  PRODUCT_ITEMS_COMPONENT: 'ProductItems', // 横滑自选商品组件,
  STORE_DAILYNEW_ITEMS: 'StoreDailynew', // 店铺dailynew商品列表组件
  STORE_LOOKBOOK: 'StoreLookbook', // 店铺lookbook组件
  STORE_CATEGORY_RECOMMEND: 'StoreCategoryRecommend', // 品类热度推荐(店铺)
  STORE_ITEM_IMAGE_MULTI_CAROUSEL: 'StoreSideslipImage', // 滑动多图组件 （轮播图、横滑3、4、5、6）
  STORE_HORIZONTAL_ITEMS: 'StoreHorizontalItems', // 横向商品列表
  STORE_VERTICAL_ITEMS: 'StoreVerticalItems', // 纵向商品列表
  STORE_FLASH_SALE: 'StoreFlashSale', // 店铺闪购组件
  STORE_DISCOUNT_PRODUCTS_RECOMMEND: 'StoreDiscountProductsRecommend', // 店铺折扣商品组件
  SUPER_DEALS: 'SuperDeals',  // 不贵组件
  NEWS_SECTION: 'NewsSection', // 新闻板块组件
  DRAW_SPIN: 'DrawSpin',  // 转盘抽奖组件
  STORE_VIDEO: 'StoreVideo', // 店铺视频
  STORE_HOT_PRODUCTS_RECOMMEND: 'StoreRankHotSale', // 店铺装修热销榜
  STORE_NEW_ARRIVALS_RECOMMEND: 'StoreNewArrivalList', // 店铺装修上新
  STORE_CATEGORY_DISPLAY_RECOMMEND: 'StoreCategoryDisplayRecommend', // 店铺品类推荐
}

export const compMapping = ({ styleType = '', componentKey = '' }) => {
  return compTypes[styleType] || compTypes[componentKey] || ''
}
